import { Button } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useAppSelector } from "../../store/hook";

import UserIcon from "../../assets/icons/user";
import logoW from "../../assets/images/logo.png";
import cmitLogo from "../../assets/images/partners/cmit.png";
import spilfLogo from "../../assets/images/partners/spilf.png";
import mesVaccinsLogo from "../../assets/images/partners/mes-vaccins-net-blue.png";
import footerE from "../../assets/images/footer-e.svg";
import footerP from "../../assets/images/footer-p.svg";

const menus = [
  {
    title: "Accès rapide",
    links: [
      { label: "Infections", to: "/categorie/1" },
      { label: "Vaccins", to: "/categorie/4" },
      { label: "Microorganismes", to: "/categorie/2" },
      { label: "Anti-infectieux", to: "/categorie/3" },
      { label: "Antibioprophylaxie en chirurgie", to: "/categorie/5" },
      {
        label: "Accidents d’exposition au sang et aux liquides biologiques",
        to: "/categorie/6",
      },
      {
        label: "Recommandations de la Spilf",
        to: "https://www.infectiologie.com/fr/recommandations.html",
        target: "_blank",
      },
    ],
  },
  {
    title: "Aide",
    links: [
      { label: "FAQ", to: "/" },
      { label: "Nous contacter", to: "/" },
    ],
  },
  {
    title: "Légal",
    links: [
      { label: "Mentions légales", to: "/" },
      { label: "Politique de confidentialité", to: "/" },
    ],
  },
];

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isHome = location.pathname === "/";
  const auth = useAppSelector((state) => state.auth);
  const { isAuth } = auth;

  return (
    <footer>
      {!isAuth && isHome && (
        <div className="footer__not-auth--container">
          <div className="footer__not-auth">
            <h2 className="footer__not-auth--title">Rejoindre ePOPI</h2>
            <div className="footer__not-auth--actions">
              <Button
                sx={{
                  color: "white",
                  borderColor: "white",
                  "&:hover": {
                    backgroundColor: "var(--light-navy)",
                    borderColor: "white",
                  },
                }}
                variant="outlined"
                size="large"
                onClick={() => navigate("/connexion/s-inscrire")}
              >
                S'abonner
              </Button>
              <Button
                sx={{
                  color: "var(--navy)",
                  textWrap: "nowrap",
                  backgroundColor: "white",
                  "&:hover": {
                    backgroundColor: "var(--blue-background)",
                  },
                }}
                variant="contained"
                size="large"
                onClick={() => navigate("connexion/se-connecter")}
              >
                <UserIcon />
                <p>Se connecter</p>
              </Button>
            </div>

            <img
              className="footer__not-auth--background-e"
              src={footerE}
              alt="footer e"
            />
            <img
              className="footer__not-auth--background-p"
              src={footerP}
              alt="footer p"
            />
          </div>

          <div className="footer__not-auth--blue-background" />
        </div>
      )}

      <div className="footer">
        <div className="footer__epopi-infos">
          <img className="footer__epopi-logo" src={logoW} alt="epopi-white" />

          <p className="footer__epopi-description">
            Maladies Infectieuses et Tropicales
            <br />
            <br />
            <span>
              Guide pratique d’aide à la prescription des anti-infectieux
            </span>
          </p>

          <div className="footer__partners">
            <img src={cmitLogo} alt="cmit-logo" />
            <img src={spilfLogo} alt="spilf-logo" />
            <img src={mesVaccinsLogo} alt="mes-vaccins-logo" />
          </div>
        </div>

        <div className="footer__links">
          {menus.map((menu, index) => (
            <div key={index} className="footer__link-item">
              <p className="footer__link-item--title">{menu.title}</p>

              <div className="footer__link-item--links">
                {menu.links.map((link, index) => (
                  <Link key={index} to={link.to} target={link.target}>
                    {link.label}
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
